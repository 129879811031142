<template>

    <div>

    <headbar></headbar>

    <section class="section__settings container" id="settings">

        <div class="columns">

            <div class="column">

              <h1>Settings</h1>



                <p>Update your profile</p>

              <transition name="fade">
                <p v-if="showSuccess" class="success">profile updated</p>
              </transition>



              <form @submit.prevent>

                  <b-field label="Name"
                           >
                      <b-input type="text"
                               v-model.trim="name" :placeholder="name" id="name"
                               maxlength="60"
                      >
                      </b-input>
                  </b-field>

                  <b-field label="Company"
                  >
                      <b-input type="text"
                               v-model.trim="userProfile.title" :placeholder="userProfile.title" id="title"
                               maxlength="60"
                      >
                      </b-input>
                  </b-field>

                <button @click="updateProfile()" class="button">Update Profile</button>

                  <br /><br />

                  <router-link
                          to="/">

                      <button class="button">Back to fabric design</button>

                  </router-link>

              </form>

            </div>

        </div>

      </section>

    </div>


</template>

<script>

import { mapState } from 'vuex'

import Headbar from "../components/headbar.vue";



export default {
  data() {
    return {
      name: '',
      title: '',
      showSuccess: false
    }
  },
    components: {
        Headbar
    },
  computed: {
    ...mapState(['userProfile'])
  },
    mounted() {
        this.name = this.userProfile.name
    this.title = this.userProfile.title
    },
  methods: {
    updateProfile() {
      this.$store.dispatch('updateProfile', {
        name: this.name !== '' ? this.name : this.userProfile.name,
        title: this.title !== '' ? this.title : this.userProfile.title
      })

      //this.name = ''
      //this.title = ''

      this.showSuccess = true

      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    }
  }
}
</script>

<style lang="scss">



    body {
        font-family: 'Jost', sans-serif;
    }

    .section {

        &__settings {

            margin-top: 80px;

            h1 {
                font-size: 6vw;
                font-weight: 900;
                text-transform: uppercase;
            }


            p {
                font-size: 1rem;
                margin: 15px 0;
            }

        }

    }




</style>
